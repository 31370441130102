.activeOrder {
    background-color: lightgray;
    display: inline;
    float: right;
    max-width: 300px;
    padding: 10px;
}
.addedToOrder{
    background-color: rgb(244, 195, 195);
    display: inline; 
    width: 200px;
    padding: 5px 10px 5px 10px;
    font-size: 12px;
    margin: 0px 0px 0px 10px;
}
.activeOrder h2 {
    font-size: 12px;
    margin: 0px;
}
.activeOrder li {
    font-size: 12px;
    font-weight: normal;
}



.activeOrder ul{
    margin: 0px
}
.goToOrder {
    float: right;
}